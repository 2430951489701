import React from 'react';

import { Link } from 'gatsby';
import { rhythm } from '../utils/typography';

const PostPreview = ({ post }) => {
    const title = post.frontmatter.title || post.fields.slug;
    return (
        <div>
            <h3
                style={{
                    marginBottom: rhythm(1 / 4),
                }}
            >
                <Link style={{ boxShadow: `none` }} to={post.fields.slug}>
                    {title}
                </Link>
            </h3>
            <small>
                {post.frontmatter.date} • {post.timeToRead}{' '}
                {post.fields.langKey === 'de' ? 'Min. Lesedauer' : 'min read'}
            </small>
            <p
                dangerouslySetInnerHTML={{
                    __html: post.frontmatter.description || post.excerpt,
                }}
            />
        </div>
    );
};

export default PostPreview;
