import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { TopHeading } from '../components/heading';
import PostPreview from '../components/postpreview';

class BlogIndexTemplate extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;
        const posts = data.posts.edges;
        const langKey = this.props.pageContext.langKey;

        return (
            <Layout
                location={this.props.location}
                title={siteTitle}
                lang="en"
                hasGerman={true}
                slug={this.props.path}
            >
                <SEO
                    lang={langKey}
                    description={data.site.siteMetadata.description}
                />
                <TopHeading>Welcome!</TopHeading>
                <p>
                    This is the blog of Nicolas Hollmann. Here I show some
                    projects I've worked on and write about things that interest
                    me. The most content is of a technical nature.
                </p>

                <h3>Latest 4 Blogposts:</h3>
                {posts.map(({ node }) => {
                    return <PostPreview key={node.fields.slug} post={node} />;
                })}
            </Layout>
        );
    }
}

export default BlogIndexTemplate;

export const pageQuery = graphql`
    query($langKey: String!) {
        site {
            siteMetadata {
                title
                description
            }
        }
        posts: allMarkdownRemark(
            filter: {
                fields: { langKey: { eq: $langKey } }
            }
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 4
        ) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                        langKey
                    }
                    timeToRead
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                    }
                }
            }
        }
    }
`;
